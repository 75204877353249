










































































































































import Vue from "vue";

import CustomImage from "@/components/CustomImage.vue";
import DialogImage from "@/components/DialogImage.vue";

export default Vue.extend({
  name: "InfraestruturaMicrotomografia",
  components: {
    CustomImage,
    DialogImage,
  },
  data: () => ({
    exemplos: [
      {
        imgSrc: "/img/infraestrutura/microtomografia/aplicacao_01.jpg",
        thumbnailSrc:
          "/img/infraestrutura/microtomografia/thumb_aplicacao_01.jpg",
        caption: "Exemplo 01",
      },
      {
        imgSrc: "/img/infraestrutura/microtomografia/aplicacao_02.jpg",
        thumbnailSrc:
          "/img/infraestrutura/microtomografia/thumb_aplicacao_02.jpg",
        caption: "Exemplo 02",
      },
      {
        imgSrc: "/img/infraestrutura/microtomografia/aplicacao_03.jpg",
        thumbnailSrc:
          "/img/infraestrutura/microtomografia/thumb_aplicacao_03.jpg",
        caption: "Exemplo 03",
      },
      {
        imgSrc: "/img/infraestrutura/microtomografia/aplicacao_04.jpg",
        thumbnailSrc:
          "/img/infraestrutura/microtomografia/thumb_aplicacao_04.jpg",
        caption: "Exemplo 04",
      },
      {
        imgSrc: "/img/infraestrutura/microtomografia/aplicacao_05.jpg",
        thumbnailSrc:
          "/img/infraestrutura/microtomografia/thumb_aplicacao_05.jpg",
        caption: "Exemplo 05",
      },
      {
        imgSrc: "/img/infraestrutura/microtomografia/aplicacao_06.jpg",
        thumbnailSrc:
          "/img/infraestrutura/microtomografia/thumb_aplicacao_06.jpg",
        caption: "Exemplo 06",
      },
      {
        imgSrc: "/img/infraestrutura/microtomografia/aplicacao_07.jpg",
        thumbnailSrc:
          "/img/infraestrutura/microtomografia/thumb_aplicacao_07.jpg",
        caption: "Exemplo 07",
      },
      {
        imgSrc: "/img/infraestrutura/microtomografia/aplicacao_08.jpg",
        thumbnailSrc:
          "/img/infraestrutura/microtomografia/thumb_aplicacao_08.jpg",
        caption: "Exemplo 08",
      },
      {
        imgSrc: "/img/infraestrutura/microtomografia/aplicacao_09.jpg",
        thumbnailSrc:
          "/img/infraestrutura/microtomografia/thumb_aplicacao_09.jpg",
        caption: "Exemplo 09",
      },
      {
        imgSrc: "/img/infraestrutura/microtomografia/aplicacao_10.jpg",
        thumbnailSrc:
          "/img/infraestrutura/microtomografia/thumb_aplicacao_10.jpg",
        caption: "Exemplo 10",
      },
    ],
  }),
});
