



































































































import Vue from "vue";

import CustomImage from "@/components/CustomImage.vue";
import DialogImage from "@/components/DialogImage.vue";

export default Vue.extend({
  name: "InfraestruturaConfocal",
  components: {
    CustomImage,
    DialogImage,
  },
  data: () => ({
    exemplos: [
      {
        imgSrc: "/img/infraestrutura/confocal/aplicacao_01.jpg",
        thumbnailSrc: "/img/infraestrutura/confocal/thumb_aplicacao_01.jpg",
        caption: "Exemplo 01",
      },
      {
        imgSrc: "/img/infraestrutura/confocal/aplicacao_02.jpg",
        thumbnailSrc: "/img/infraestrutura/confocal/thumb_aplicacao_02.jpg",
        caption: "Exemplo 02",
      },
      {
        imgSrc: "/img/infraestrutura/confocal/aplicacao_03.jpg",
        thumbnailSrc: "/img/infraestrutura/confocal/thumb_aplicacao_03.jpg",
        caption: "Exemplo 03",
      },
      {
        imgSrc: "/img/infraestrutura/confocal/aplicacao_04.jpg",
        thumbnailSrc: "/img/infraestrutura/confocal/thumb_aplicacao_04.jpg",
        caption: "Exemplo 04",
      },
      {
        imgSrc: "/img/infraestrutura/confocal/aplicacao_05.jpg",
        thumbnailSrc: "/img/infraestrutura/confocal/thumb_aplicacao_05.jpg",
        caption: "Exemplo 05",
      },
    ],
  }),
});
