



































































































































import Vue from "vue";

import AvisoSolicitacao from "@/components/AvisoSolicitacao.vue";

export default Vue.extend({
  name: "MetodosCimento",
  components: {
    AvisoSolicitacao,
  },
});
