














































































































































import Vue from "vue";

import CustomImage from "@/components/CustomImage.vue";
import DialogImage from "@/components/DialogImage.vue";

export default Vue.extend({
  name: "InfraestruturaOptica",
  components: {
    CustomImage,
    DialogImage,
  },
  data: () => ({
    exemplosTransmitida: [
      {
        imgSrc: "/img/infraestrutura/optica/transmitida_01.jpg",
        thumbnailSrc: "/img/infraestrutura/optica/thumb_transmitida_01.jpg",
        caption: "Exemplo 01",
      },
      {
        imgSrc: "/img/infraestrutura/optica/transmitida_02.jpg",
        thumbnailSrc: "/img/infraestrutura/optica/thumb_transmitida_02.jpg",
        caption: "Exemplo 02",
      },
    ],
    exemplosRefletida: [
      {
        imgSrc: "/img/infraestrutura/optica/refletida_01.jpg",
        thumbnailSrc: "/img/infraestrutura/optica/thumb_refletida_01.jpg",
        caption: "Exemplo 01",
      },
      {
        imgSrc: "/img/infraestrutura/optica/refletida_02.jpg",
        thumbnailSrc: "/img/infraestrutura/optica/thumb_refletida_02.jpg",
        caption: "Exemplo 02",
      },
      {
        imgSrc: "/img/infraestrutura/optica/refletida_03.jpg",
        thumbnailSrc: "/img/infraestrutura/optica/thumb_refletida_03.jpg",
        caption: "Exemplo 03",
      },
    ],
    exemplosEstereo: [
      {
        imgSrc: "/img/infraestrutura/optica/estereo_01.jpg",
        thumbnailSrc: "/img/infraestrutura/optica/thumb_estereo_01.jpg",
        caption: "Exemplo 01",
      },
      {
        imgSrc: "/img/infraestrutura/optica/estereo_02.jpg",
        thumbnailSrc: "/img/infraestrutura/optica/thumb_estereo_02.jpg",
        caption: "Exemplo 02",
      },
      {
        imgSrc: "/img/infraestrutura/optica/estereo_03.jpg",
        thumbnailSrc: "/img/infraestrutura/optica/thumb_estereo_03.jpg",
        caption: "Exemplo 03",
      },
    ],
  }),
});
