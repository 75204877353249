




























































import Vue from "vue";
export default Vue.extend({
  name: "Contato",
  data: () => ({
    mapKey: "AIzaSyDXtaQHlJNoU46iNXK4b4c7s7sY0jW-PT4",
  }),
});
