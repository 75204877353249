




































import Vue from "vue";
export default Vue.extend({
  name: "Infraestrutura",
  data: () => ({
    items: [
      {
        title: "Microscopia",
        imgSrc: "/img/infraestrutura/microscopia.jpg",
        subItems: [
          {
            title: "Microtomografia",
            to: { name: "InfraestruturaMicrotomografia" },
          },
          {
            title: "Microscopia eletrônica MEV",
            to: { name: "InfraestruturaMev" },
          },
          {
            title: "Análise de imagens MLA",
            to: { name: "InfraestruturaMla" },
          },
          {
            title: "Microscopia confocal",
            to: { name: "InfraestruturaConfocal" },
          },
          {
            title: "Microscopia óptica",
            to: { name: "InfraestruturaOptica" },
          },
        ],
      },
      {
        title: "Análises químicas e mineralógicas",
        imgSrc: "/img/infraestrutura/aqm.jpg",
        subItems: [
          {
            title: "Fluorescência de raios X",
            to: { name: "InfraestruturaFrx" },
          },
          {
            title: "ICP OES",
            to: { name: "InfraestruturaIcp" },
          },
          {
            title: "Absorção atômica",
            to: { name: "InfraestruturaAas" },
          },
          {
            title: "Via úmida",
            to: { name: "InfraestruturaAvu" },
          },
          {
            title: "Difração de raios X",
            to: { name: "InfraestruturaDrx" },
          },
        ],
      },
      {
        title: "Tamanho e forma de partículas",
        imgSrc: "/img/infraestrutura/particulas.jpg",
        subItems: [
          {
            title: "Espalhamento a laser",
            to: { name: "InfraestruturaParticulas" },
          },
          {
            title: "Análise de imagens dinâmica",
            to: { name: "InfraestruturaParticulas" },
          },
          {
            title: "Peneiramento",
            to: { name: "InfraestruturaParticulas" },
          },
          {
            title: "Classificação hidrodinâmica",
            to: { name: "InfraestruturaParticulas" },
          },
        ],
      },
      {
        title: "Porosidade, densidade e análise térmica",
        imgSrc: "/img/infraestrutura/porosidade.jpg",
        subItems: [
          {
            title: "Porosimetria mercúrio",
            to: { name: "InfraestruturaPorosidade" },
          },
          {
            title: "Picnometria hélio",
            to: { name: "InfraestruturaPorosidade" },
          },
          {
            title: "Picnometria pó",
            to: { name: "InfraestruturaPorosidade" },
          },
          {
            title: "Picnometria água/álcool",
            to: { name: "InfraestruturaPorosidade" },
          },
          {
            title: "Análise térmica",
            to: { name: "InfraestruturaAnaliseTermica" },
          },
        ],
      },
      {
        title: "Separações minerais",
        imgSrc: "/img/infraestrutura/separacoes.jpg",
        subItems: [
          {
            title: "Densitária",
            to: { name: "InfraestruturaSeparacoes" },
          },
          {
            title: "Magnética",
            to: { name: "InfraestruturaSeparacoes" },
          },
          {
            title: "Eletrostática pó",
            to: { name: "InfraestruturaSeparacoes" },
          },
          {
            title: "Atrição",
            to: { name: "InfraestruturaSeparacoes" },
          },
        ],
      },
      {
        title: "Preparações físicas",
        imgSrc: "/img/infraestrutura/preparacoes.jpg",
        subItems: [
          {
            title: "Britadores",
            to: { name: "InfraestruturaPreparacoes" },
          },
          {
            title: "Moinhos",
            to: { name: "InfraestruturaPreparacoes" },
          },
          {
            title: "Amostradores",
            to: { name: "InfraestruturaPreparacoes" },
          },
          {
            title: "Acionadores e correlatos",
            to: { name: "InfraestruturaPreparacoes" },
          },
        ],
      },
    ],
  }),
});
