


































































































import Vue from "vue";
import AvisoSolicitacao from "@/components/AvisoSolicitacao.vue";

export default Vue.extend({
  name: "MetodosFrx",
  components: {
    AvisoSolicitacao,
  },
  data: () => ({
    quantitativos: [
      {
        title: "Bauxitas",
        items: [
          {
            title: "Código BAX-1",
            content:
              "Al<sub>2</sub>O<sub>3</sub>, Fe<sub>2</sub>O<sub>3</sub>, SiO<sub>2</sub>, TiO<sub>2</sub> + PF",
          },
          {
            title: "Código BAX-2",
            content:
              "Al<sub>2</sub>O<sub>3</sub>, Fe<sub>2</sub>O<sub>3</sub>, SiO<sub>2</sub>, TiO<sub>2</sub>, P<sub>2</sub>O<sub>5</sub>, CaO, V<sub>2</sub>O<sub>5</sub>, Cr<sub>2</sub>O<sub>3</sub>, MnO, ZrO<sub>2</sub> + PF",
          },
        ],
      },
      {
        title: "Calcários",
        items: [
          {
            title: "Código CAC-1",
            content:
              "CaO, MgO, SiO<sub>2</sub>, Al<sub>2</sub>O<sub>3</sub>, Fe<sub>2</sub>O<sub>3</sub> + PF",
          },
          {
            title: "Código CAC-2",
            content:
              "CaO, MgO, SiO<sub>2</sub>, Al<sub>2</sub>O<sub>3</sub>, Fe<sub>2</sub>O<sub>3</sub>, Na<sub>2</sub>O, K<sub>2</sub>O, P<sub>2</sub>O<sub>5</sub>, TiO<sub>2</sub>, SrO, MnO, S + PF",
          },
        ],
      },
      {
        title: "Cimentos",
        items: [
          {
            title: "Código CIM-1",
            content:
              "SiO<sub>2</sub>, Al<sub>2</sub>O<sub>3</sub>, Fe<sub>2</sub>O<sub>3</sub>, CaO, MgO, SO<sub>3</sub>, Na<sub>2</sub>O, K<sub>2</sub>O, TiO<sub>2</sub>, P<sub>2</sub>O<sub>5</sub>, MnO, SrO + PF",
          },
        ],
      },
      {
        title: "Fosfatos",
        items: [
          {
            title: "Código FOS-1",
            content:
              "P<sub>2</sub>O<sub>5</sub>, CaO, SiO<sub>2</sub>, Al<sub>2</sub>O<sub>3</sub>, Fe<sub>2</sub>O<sub>3</sub>, MgO + PF",
          },
          {
            title: "Código FOS-2",
            content:
              "P<sub>2</sub>O<sub>5</sub>, CaO, SiO<sub>2</sub>, Al<sub>2</sub>O<sub>3</sub>, Fe<sub>2</sub>O<sub>3</sub>, MgO, TiO<sub>2</sub>, BaO, SrO + PF",
          },
          {
            title: "Código FOS-3",
            content:
              "P<sub>2</sub>O<sub>5</sub>, CaO, SiO<sub>2</sub>, Al<sub>2</sub>O<sub>3</sub>, Fe<sub>2</sub>O<sub>3</sub>, MgO, TiO<sub>2</sub>, BaO, SrO, Na<sub>2</sub>O, K<sub>2</sub>O, MnO, SO<sub>3</sub>, ZrO<sub>2</sub>, Nb<sub>2</sub>O<sub>5</sub> + PF",
          },
        ],
      },
      {
        title: "Fosfatos com terras raras",
        items: [
          {
            title: "Código FTR-1",
            content:
              "P<sub>2</sub>O<sub>5</sub>, CaO, SiO<sub>2</sub>, Al<sub>2</sub>O<sub>3</sub>, Fe<sub>2</sub>O<sub>3</sub>, MgO, TiO<sub>2</sub>, BaO, SrO, Na<sub>2</sub>O, K<sub>2</sub>O, MnO, SO<sub>3</sub>, ZrO<sub>2</sub>, Nb<sub>2</sub>O<sub>5</sub>, CeO<sub>2</sub>, La<sub>2</sub>O<sub>3</sub>, Nd<sub>2</sub>O<sub>3</sub>, Pr<sub>2</sub>O<sub>3</sub>, ThO<sub>2</sub>, U<sub>3</sub>O<sub>8</sub> + PF",
          },
        ],
      },
      {
        title: "Minérios de ferro",
        items: [
          {
            title: "Código FER-1",
            content:
              "Fe, SiO<sub>2</sub>, Al<sub>2</sub>O<sub>3</sub>, P e Mn + PF",
          },
          {
            title: "Código FER-2",
            content:
              "Fe, SiO<sub>2</sub>, Al<sub>2</sub>O<sub>3</sub>, P, Mn, TiO<sub>2</sub>, CaO, MgO, K<sub>2</sub>O, Na<sub>2</sub>O, Cr<sub>2</sub>O<sub>3</sub> + PF",
          },
        ],
      },
      {
        title: "Minérios de manganês",
        items: [
          {
            title: "Código MAN-1",
            content:
              "Mn, Fe, P, SiO<sub>2</sub>, Al<sub>2</sub>O<sub>3</sub>, K<sub>2</sub>O, BaO + PF",
          },
          {
            title: "Código MAN-2",
            content:
              "Mn, Fe, P, SiO<sub>2</sub>, Al<sub>2</sub>O<sub>3</sub>, K<sub>2</sub>O, BaO, TiO<sub>2</sub>, CaO, MgO, Na<sub>2</sub>O + PF",
          },
        ],
      },
      {
        title: "Quartzo e Feldspato",
        items: [
          {
            title: "Código QZF-1",
            content:
              "SiO<sub>2</sub>, Al<sub>2</sub>O<sub>3</sub>, Fe<sub>2</sub>O<sub>3</sub>, MnO, MgO, CaO, Na<sub>2</sub>O, K<sub>2</sub>O, TiO<sub>2</sub>, P<sub>2</sub>O<sub>5</sub>, Cr<sub>2</sub>O<sub>3</sub>, ZrO<sub>2</sub> + PF",
          },
        ],
      },
      {
        title: "Rochas",
        items: [
          {
            title: "Código ROC-1",
            content:
              "SiO<sub>2</sub>, Al<sub>2</sub>O<sub>3</sub>, Fe<sub>2</sub>O<sub>3</sub>, MnO, MgO, CaO, Na<sub>2</sub>O, K<sub>2</sub>O, TiO<sub>2</sub>, P<sub>2</sub>O<sub>5</sub> + PF",
          },
        ],
      },
    ],
  }),
});
