const features = [
  // {
  //   id: 40,
  //   title: "Vaga: Técnico de Laboratório LCT USP",
  //   description:
  //     "Atuação em projetos de caracterização na área de geologia e mineração. Clique no botão abaixo para saber mais.",
  //   imgSrc: "/img/tempdata/features/40.jpg",
  //   articleId: null,
  //   link: "/files/Vaga_LCT-Tecnico-Lab_Mineral_USP.pdf",
  //   created: "2022-02-24 19:40:00",
  //   modified: "2022-02-24 19:40:00",
  // },
  // {
  //   id: 26,
  //   title: "COVID-19",
  //   imgSrc: "/img/tempdata/features/26.png",
  //   description:
  //     "Em decorrência das restrições adotadas pela USP, determinadas pelo Governador de São Paulo, o LCT está com acesso restrito a um mínimo de sua equipe. Como consequências, o atendimento presencial foi suspenso e por telefone está bastante limitado...",
  //   articleId: 35,
  //   link: "",
  //   created: "2020-03-23 15:50:00",
  //   modified: "2020-03-23 15:50:00",
  // },
  // {
  //   id: 23,
  //   title: "ARTIGO CIENTÍFICO EM REVISTA DE ALTO IMPACTO",
  //   imgSrc: "/img/tempdata/features/23.jpg",
  //   description:
  //     "Pesquisadores do LCT publicam artigo científico em revista de alto impacto no âmbito de uma parceria com instituição de referência da Alemanha.",
  //   articleId: null,
  //   link: "https://doi.org/10.1016/j.conbuildmat.2019.116875",
  //   created: "2019-09-20 09:49:00",
  //   modified: "2019-09-20 09:49:00",
  // },
  // {
  //   id: 22,
  //   title: "Centros Multiusuários",
  //   imgSrc: "/img/tempdata/features/22.jpg",
  //   description:
  //     "O LCT participou da reportagem do G1-Globo como um dos Centros Multiusuários mais ativos da USP.",
  //   articleId: 32,
  //   link: "",
  //   created: "2019-09-20 09:00:00",
  //   modified: "2019-09-20 09:00:00",
  // },
  // {
  //   id: 20,
  //   title:
  //     "Publicação da Brasil Mineral: “A Contribuição do LCT para a indústria mineral”",
  //   imgSrc: "/img/tempdata/features/20.jpg",
  //   description:
  //     "A importância da caracterização tecnológica nas diversas etapas de um empreendimento mineiro e uma visão de futuro do LCT foram abordadas em matéria publicada pela Brasil Mineral.",
  //   articleId: 31,
  //   link: "",
  //   created: "2019-09-13 09:05:00",
  //   modified: "2019-09-13 09:05:00",
  // },
  {
    id: 11,
    title: " 	LABORATÓRIO DE CARACTERIZAÇÃO TECNOLÓGICA",
    imgSrc: "/img/tempdata/features/11.jpg",
    description:
      "O Laboratório de Caracterização Tecnológica (LCT) está vinculado ao Departamento de Engenharia de Minas e de Petróleo da Escola Politécnica da USP. Desde sua criação, em 1990, dedica-se a estudos e pesquisas na área de caracterização tecnológica de mat...​",
    articleId: null,
    link: "/sobre/lct",
    created: "2018-05-29 17:46:00",
    modified: "2018-05-29 17:46:00",
  },
  {
    id: 13,
    title: "APLICAÇÕES DE MICROTOMOGRAFIA DE ALTA RESOLUÇÃO",
    imgSrc: "/img/tempdata/features/13.jpg",
    description:
      "Confira o nosso vídeo com exemplos de aplicações em análises já realizadas no LCT.​",
    articleId: null,
    link: "/infraestrutura/microtomografia",
    created: "2018-07-03 16:53:00",
    modified: "2018-07-03 16:53:00",
  },
];

export default features;
