import { RouteConfig } from "vue-router";

import ServicosAnalises from "../views/servicos/ServicosAnalises.vue";
import ServicosAssessorias from "../views/servicos/ServicosAssessorias.vue";
import ServicosCaracterizacao from "../views/servicos/ServicosCaracterizacao.vue";
import ServicosCursos from "../views/servicos/ServicosCursos.vue";
import ServicosLims from "../views/servicos/ServicosLims.vue";

const routes: RouteConfig[] = [
  {
    path: "/servicos/analises",
    name: "ServicosAnalises",
    component: ServicosAnalises,
    meta: {
      title: "Serviços - Análises",
    },
  },
  {
    path: "/servicos/assessorias",
    name: "ServicosAssessorias",
    component: ServicosAssessorias,
    meta: {
      title: "Serviços - Assessorias e treinamentos",
    },
  },
  {
    path: "/servicos/caracterizacao",
    name: "ServicosCaracterizacao",
    component: ServicosCaracterizacao,
    meta: {
      title: "Serviços - Caracterização tecnológica",
    },
  },
  {
    path: "/servicos/cursos",
    name: "ServicosCursos",
    component: ServicosCursos,
    meta: {
      title: "Serviços - Cursos",
    },
  },
  {
    path: "/servicos/lims",
    name: "ServicosLims",
    component: ServicosLims,
    meta: {
      title: "Serviços - Software LIMS",
    },
  },
];

export default routes;
