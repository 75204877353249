














































import Vue from "vue";

import CustomImage from "@/components/CustomImage.vue";

export default Vue.extend({
  name: "InfraestruturaAnaliseTermica",
  components: {
    CustomImage,
  },
});
