const articles = [
  {
    id: 40,
    title: "VAGA: Técnico de Laboratório LCT - USP",
    imgSrc: "/img/tempdata/articles/40.jpg",
    description:
      "Atuação: projetos de caracterização na área de geologia e mineração.\nPré-requisitos: Curso técnico em mineração, cerâmica, química, ou áreas correlacionadas. Conhecimento básico em informática e pacote office.\nClique para saber mais.",
    body: `<div align="center"><p><a href="/files/Vaga_LCT-Tecnico-Lab_Mineral_USP.pdf">Saiba mais</a></p></div>`,
    created: "2022-02-24T19:40:00.000-03:00",
    modified: "2022-02-24T19:40:00.000-03:00",
  },
  {
    id: 38,
    title: "O LCT deseja a todos Boas Festas!",
    imgSrc: "/img/tempdata/articles/38.jpg",
    description:
      "O LCT deseja a todos os parceiros, fornecedores e colaboradores um Feliz Natal, com muita paz e harmonia e um Ano Novo repleto de sonhos realizados e esperanças renovadas. Estaremos em férias coletivas de 23 de dezembro de 2021 a 3 de janeiro de 2022.",
    body: `<div align="center"><p>O LCT deseja a todos os parceiros, fornecedores e colaboradores um Feliz Natal, com muita paz e harmonia e um Ano Novo repleto de sonhos realizados e esperanças renovadas.</p><p><b>Estaremos em férias coletivas de 23 de dezembro de 2020 a 3 de janeiro de 2021.</b></p></div>`,
    created: "2021-12-22T21:04:00.000-03:00",
    modified: "2021-12-22T21:04:00.000-03:00",
  },
  {
    id: 36,
    title: "Comunicado - Antecipação dos feriados",
    imgSrc: "/img/tempdata/articles/36.jpg",
    description:
      "Em função do Decreto 59.450 de 18 de maio de 2020, publicado Diário Oficial e do Projeto de Lei 351/202, informamos que aderimos à antecipação dos feriados. O LCT retornará suas atividades no dia 26/05 (terça-feira).",
    body: `<p>Prezados Parceiros,<br><br>Em função do Decreto 59.450 de 18 de maio de 2020, publicado Diário Oficial e do Projeto de Lei 351/202, informamos que aderimos a antecipação dos seguintes feriados:<br><br>Dia 20/05 (quarta-feira) - Antecipação Feriado do dia: 11/06/2020 (Corpus Christi)<br>Dia 21/05 (quinta-feira) - Antecipação Feriado do dia: 20/11/2020 (Dia Nacional da consciência Negra)<br>Dia 25/05 (segunda-feira) - Antecipação Feriado do dia: 09/07/2020 (Revolução Constitucionalista de 1932)<br><br><b>O LCT retornará suas atividades no dia 26/05 (terça-feira).</b></p><p>Att,</p><p>Equipe LCT<br></p>`,
    created: "2020-05-21T15:04:00.000-03:00",
    modified: "2020-05-21T15:04:00.000-03:00",
  },
  {
    id: 35,
    title: "COVID-19",
    imgSrc: "/img/tempdata/articles/35.png",
    description:
      "Em decorrência das restrições adotadas pela USP, determinadas pelo Governador de São Paulo, o LCT está com acesso restrito a um mínimo de sua equipe. Como consequências, o atendimento presencial foi suspenso e por telefone está bastante limitado...",
    body: `<p align="justify">Em decorrência das restrições adotadas pela USP, determinadas pelo Governador de São Paulo, o LCT está com acesso restrito a um mínimo de sua equipe. Como consequências, o atendimento presencial foi suspenso e por telefone está bastante limitado. A maior parte da equipe já está em teletrabalho e a melhor forma de comunicação nesse momento é por e-mail. Estamos comprometidos em manter o atendimento aos nossos usuários de maneira não presencial, sempre respeitando as determinações da USP e mantendo os cuidados de saúde de nossa equipe e usuários do Centro Multiusuário.</p><p align="justify">Solicitamos que entre em contato pelo e-mail <b><a href="mailto:lct@lct.poli.usp.br" target="_blank">lct@lct.poli.usp.br</a></b> ou pelo nosso WhatsApp <b><a href="https://api.whatsapp.com/send?phone=5511941527407" target="_blank">+55 (11) 94152-7407</a></b>.<br></p><p align="justify">Vamos juntos enfrentar essa pandemia do novo Coronavírus para que tudo volte à normalidade o mais breve possível.</p><p align="justify">Contamos com a compreensão de todos.<br></p>`,
    created: "2020-05-21T15:04:00.000-03:00",
    modified: "2020-05-21T15:04:00.000-03:00",
  },
  {
    id: 34,
    title: "O LCT deseja Boas Festas! ",
    imgSrc: "/img/tempdata/articles/34.jpg",
    description: "Estaremos em férias coletivas de 20/Dez a 05/Jan.",
    body: `<p align="center"><b>Estaremos em férias coletivas de 20/Dez a 05/Jan.</b><br></p>`,
    created: "2019-12-17T14:22:00.000-03:00",
    modified: "2019-12-17T14:22:00.000-03:00",
  },
  {
    id: 33,
    title:
      "20º ORE AND MINERALS ANALYSIS (OMA) WORKSHOP - INSCRIÇÕES GRATUITAS",
    imgSrc: "/img/tempdata/articles/33.jpg",
    description:
      "O evento ocorrerá no dia 13 de novembro no Departamento de Engenharia de Minas e de Petróleo da Escola Politécnica da USP.",
    body: `<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:3.0pt;
    margin-left:0cm;line-height:150%"><span style="font-size: 12pt; line-height: 150%; font-family: Arial, sans-serif; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">A Malvern Panalytical
    Brasil em conjunto com o Laboratório de Caracterização Tecnológica da USP
    realizará um workshop dedicado à indústria de mineração, minerais e metais. O workshop
    de um dia fornecerá aos profissionais envolvidos na análise de matérias primas
    uma visão geral das técnicas analíticas para exploração e monitoramento de processos,
    do upstream ao controle de qualidade e monitoramento ambiental.&nbsp;</span></p><p class="MsoNormal" style="margin-top:18.0pt;margin-right:0cm;margin-bottom:
    6.0pt;margin-left:0cm;line-height:18.0pt;mso-line-height-rule:exactly"><b><span style="font-size: 12pt; font-family: Arial, sans-serif; color: rgb(0, 176, 80); background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">Público-alvo<o:p></o:p></span></b></p><p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:3.0pt;
    margin-left:0cm;line-height:150%"><span style="font-size: 12pt; line-height: 150%; font-family: Arial, sans-serif; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">Profissionais que
    trabalham em empresas de mineração, minerais, metais e petroquímicos, pesquisadores,
    professores e associações técnicas, setor público e privado em busca de
    conhecimento e novas técnicas de análise.<o:p></o:p></span></p><p class="MsoNormal" style="margin-top:18.0pt;margin-right:0cm;margin-bottom:
    6.0pt;margin-left:0cm;line-height:18.0pt;mso-line-height-rule:exactly"><b><span style="font-size: 12pt; font-family: Arial, sans-serif; color: rgb(0, 176, 80); background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">Data e Local<o:p></o:p></span></b></p><p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:3.0pt;
    margin-left:0cm;line-height:normal"><span style="font-size: 12pt; font-family: Arial, sans-serif; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">13 de Novembro de 2019 – 08:00h as 18:00h<o:p></o:p></span></p><p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:3.0pt;
    margin-left:0cm;line-height:normal"><span style="font-size: 12pt; font-family: Arial, sans-serif; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;"><o:p>&nbsp;</o:p></span></p><p class="MsoNormal" style="margin-bottom:0cm;margin-bottom:.0001pt;line-height:
    150%"><span style="font-size: 12pt; line-height: 150%; font-family: Arial, sans-serif; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">Laboratório de Caracterização Tecnológica – LCT <o:p></o:p></span></p><p class="MsoNormal" style="margin-bottom:0cm;margin-bottom:.0001pt;line-height:
    150%"><span style="font-size: 12pt; line-height: 150%; font-family: Arial, sans-serif; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">Departamento de Engenharia de Minas e de Petróleo <o:p></o:p></span></p><p class="MsoNormal" style="margin-bottom:0cm;margin-bottom:.0001pt;line-height:
    150%"><span style="font-size: 12pt; line-height: 150%; font-family: Arial, sans-serif; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">Escola Politécnica da Universidade de São Paulo<o:p></o:p></span></p><p class="MsoNormal" style="margin-bottom:0cm;margin-bottom:.0001pt;line-height:
    150%"><span style="font-size: 12pt; line-height: 150%; font-family: Arial, sans-serif; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">Av. Prof. Mello Moraes, 2373, Butantã<o:p></o:p></span></p><p class="MsoNormal" style="margin-top:18.0pt;margin-right:0cm;margin-bottom:
    6.0pt;margin-left:0cm;line-height:18.0pt;mso-line-height-rule:exactly"><b><span style="font-size: 12pt; font-family: Arial, sans-serif; color: rgb(0, 176, 80); background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">Inscrição<o:p></o:p></span></b></p><p class="MsoNormal" style="margin-bottom:0cm;margin-bottom:.0001pt;line-height:
    150%"><span style="font-size: 12pt; line-height: 150%; font-family: Arial, sans-serif; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">Vagas limitadas e sujeitas a confirmação<o:p></o:p></span></p><p class="MsoNormal" style="margin-bottom:0cm;margin-bottom:.0001pt;line-height:
    150%"><span style="font-size: 12pt; line-height: 150%; font-family: Arial, sans-serif; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">As inscrições são gratuitas e devem ser realizadas até dia
    08/11.<o:p></o:p></span></p><p class="MsoNormal" style="margin-bottom:0cm;margin-bottom:.0001pt;line-height:
    150%">
    
    
    
    </p><p class="MsoNormal" style="margin-bottom:0cm;margin-bottom:.0001pt;line-height:
    150%"><a href="https://www.cvent.com/events/workshop-de-t-cnicas-anal-ticas-na-minera-o-oma-workshop-usp-lct/registration-5e856e8fb9014d84afb61a6aec468cdb.aspx?refid=OMAWorkshopBrasil&amp;fqp=true" target="_blank" style="font-weight: bold;">Clique aqui</a>&nbsp;para realizar sua inscrição<br></p><p class="MsoNormal" style="margin-bottom:0cm;margin-bottom:.0001pt;line-height:
    150%"><span style="font-size: 12pt; line-height: 150%; font-family: Arial, sans-serif; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:18.0pt;margin-right:0cm;margin-bottom:
    18.0pt;margin-left:0cm;line-height:18.0pt;mso-line-height-rule:exactly"><b style="font-size: 1rem;"><span style="font-size: 12pt; font-family: Arial, sans-serif; color: rgb(0, 176, 80); background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">Programação</span></b><br></p><table class="MsoTableGrid" style="width: 100%; border: none;" width="0" cellspacing="0" cellpadding="0" border="1">
     <tbody><tr>
      <td style="width:63.8pt;border:solid white 2.25pt;
      mso-border-themecolor:background1;border-left:none;background:#117074;
      padding:0cm 5.4pt 0cm 5.4pt" width="85" valign="top">
      <p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:
      3.0pt;margin-left:0cm;line-height:normal"><span style="font-family:&quot;Arial&quot;,sans-serif;
      color:white;mso-themecolor:background1">Horário<o:p></o:p></span></p>
      </td>
      <td style="width:14.0cm;border:solid white 2.25pt;
      mso-border-themecolor:background1;border-left:none;mso-border-left-alt:solid white 2.25pt;
      mso-border-left-themecolor:background1;background:#117074;padding:0cm 5.4pt 0cm 5.4pt" width="529" valign="top">
      <p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:
      3.0pt;margin-left:0cm;line-height:normal"><span style="font-family:&quot;Arial&quot;,sans-serif;
      color:white;mso-themecolor:background1">Tema<o:p></o:p></span></p>
      </td>
      <td style="width:290.6pt;border:solid white 2.25pt;
      mso-border-themecolor:background1;border-left:none;mso-border-left-alt:solid white 2.25pt;
      mso-border-left-themecolor:background1;background:#117074;padding:0cm 5.4pt 0cm 5.4pt" width="387" valign="top">
      <p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:
      3.0pt;margin-left:0cm;line-height:normal"><span style="font-family:&quot;Arial&quot;,sans-serif;
      color:white;mso-themecolor:background1">Palestrante<o:p></o:p></span></p>
      </td>
     </tr>
     <tr>
      <td style="width:63.8pt;border-top:none;border-left:none;
      border-bottom:solid white 2.25pt;mso-border-bottom-themecolor:background1;
      border-right:solid white 2.25pt;mso-border-right-themecolor:background1;
      mso-border-top-alt:solid white 2.25pt;mso-border-top-themecolor:background1;
      background:gray;mso-background-themecolor:background1;mso-background-themeshade:
      128;padding:0cm 5.4pt 0cm 5.4pt" width="85" valign="top">
      <p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:
      3.0pt;margin-left:0cm;line-height:normal"><span style="font-family:&quot;Arial&quot;,sans-serif;
      color:white;mso-themecolor:background1">8:30h<o:p></o:p></span></p>
      </td>
      <td style="width:14.0cm;border-top:none;border-left:
      none;border-bottom:solid white 2.25pt;mso-border-bottom-themecolor:background1;
      border-right:solid white 2.25pt;mso-border-right-themecolor:background1;
      mso-border-top-alt:solid white 2.25pt;mso-border-top-themecolor:background1;
      mso-border-left-alt:solid white 2.25pt;mso-border-left-themecolor:background1;
      background:gray;mso-background-themecolor:background1;mso-background-themeshade:
      128;padding:0cm 5.4pt 0cm 5.4pt" width="529" valign="top">
      <p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:
      3.0pt;margin-left:0cm;line-height:normal"><span style="font-family:&quot;Arial&quot;,sans-serif;
      color:white;mso-themecolor:background1">Abertura<o:p></o:p></span></p>
      </td>
      <td style="width:290.6pt;border-top:none;border-left:
      none;border-bottom:solid white 2.25pt;mso-border-bottom-themecolor:background1;
      border-right:solid white 2.25pt;mso-border-right-themecolor:background1;
      mso-border-top-alt:solid white 2.25pt;mso-border-top-themecolor:background1;
      mso-border-left-alt:solid white 2.25pt;mso-border-left-themecolor:background1;
      background:gray;mso-background-themecolor:background1;mso-background-themeshade:
      128;padding:0cm 5.4pt 0cm 5.4pt" width="387" valign="top">
      <p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:
      3.0pt;margin-left:0cm;line-height:normal"><span style="font-family:&quot;Arial&quot;,sans-serif;
      color:white;mso-themecolor:background1">Eduardo Macaíba / Dr. Uwe König
      (Malvern Panalytical)<o:p></o:p></span></p>
      </td>
     </tr>
     <tr>
      <td style="width:63.8pt;border-top:none;border-left:none;
      border-bottom:solid white 2.25pt;mso-border-bottom-themecolor:background1;
      border-right:solid white 2.25pt;mso-border-right-themecolor:background1;
      mso-border-top-alt:solid white 2.25pt;mso-border-top-themecolor:background1;
      background:gray;mso-background-themecolor:background1;mso-background-themeshade:
      128;padding:0cm 5.4pt 0cm 5.4pt" width="85" valign="top">
      <p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:
      3.0pt;margin-left:0cm;line-height:normal"><span style="font-family:&quot;Arial&quot;,sans-serif;
      color:white;mso-themecolor:background1">9:00h<o:p></o:p></span></p>
      </td>
      <td style="width:14.0cm;border-top:none;border-left:
      none;border-bottom:solid white 2.25pt;mso-border-bottom-themecolor:background1;
      border-right:solid white 2.25pt;mso-border-right-themecolor:background1;
      mso-border-top-alt:solid white 2.25pt;mso-border-top-themecolor:background1;
      mso-border-left-alt:solid white 2.25pt;mso-border-left-themecolor:background1;
      background:gray;mso-background-themecolor:background1;mso-background-themeshade:
      128;padding:0cm 5.4pt 0cm 5.4pt" width="529" valign="top">
      <p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:
      3.0pt;margin-left:0cm;line-height:normal"><span style="font-family:&quot;Arial&quot;,sans-serif;
      color:white;mso-themecolor:background1">Boas vindas<o:p></o:p></span></p>
      </td>
      <td style="width:290.6pt;border-top:none;border-left:
      none;border-bottom:solid white 2.25pt;mso-border-bottom-themecolor:background1;
      border-right:solid white 2.25pt;mso-border-right-themecolor:background1;
      mso-border-top-alt:solid white 2.25pt;mso-border-top-themecolor:background1;
      mso-border-left-alt:solid white 2.25pt;mso-border-left-themecolor:background1;
      background:gray;mso-background-themecolor:background1;mso-background-themeshade:
      128;padding:0cm 5.4pt 0cm 5.4pt" width="387" valign="top">
      <p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:
      3.0pt;margin-left:0cm;line-height:normal"><span style="font-family:&quot;Arial&quot;,sans-serif;
      color:white;mso-themecolor:background1">Carina Ulsen (LCT)<o:p></o:p></span></p>
      </td>
     </tr>
     <tr>
      <td style="width:63.8pt;border-top:none;border-left:none;
      border-bottom:solid white 2.25pt;mso-border-bottom-themecolor:background1;
      border-right:solid white 2.25pt;mso-border-right-themecolor:background1;
      mso-border-top-alt:solid white 2.25pt;mso-border-top-themecolor:background1;
      background:gray;mso-background-themecolor:background1;mso-background-themeshade:
      128;padding:0cm 5.4pt 0cm 5.4pt" width="85" valign="top">
      <p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:
      3.0pt;margin-left:0cm;line-height:normal"><span style="font-family:&quot;Arial&quot;,sans-serif;
      color:white;mso-themecolor:background1">9:15h<o:p></o:p></span></p>
      </td>
      <td style="width:14.0cm;border-top:none;border-left:
      none;border-bottom:solid white 2.25pt;mso-border-bottom-themecolor:background1;
      border-right:solid white 2.25pt;mso-border-right-themecolor:background1;
      mso-border-top-alt:solid white 2.25pt;mso-border-top-themecolor:background1;
      mso-border-left-alt:solid white 2.25pt;mso-border-left-themecolor:background1;
      background:gray;mso-background-themecolor:background1;mso-background-themeshade:
      128;padding:0cm 5.4pt 0cm 5.4pt" width="529" valign="top">
      <p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:
      3.0pt;margin-left:0cm;line-height:normal"><span style="font-family:&quot;Arial&quot;,sans-serif;
      color:white;mso-themecolor:background1">Espectrorradiômetro de infravermelho
      próximo (NIR)<o:p></o:p></span></p>
      </td>
      <td style="width:290.6pt;border-top:none;border-left:
      none;border-bottom:solid white 2.25pt;mso-border-bottom-themecolor:background1;
      border-right:solid white 2.25pt;mso-border-right-themecolor:background1;
      mso-border-top-alt:solid white 2.25pt;mso-border-top-themecolor:background1;
      mso-border-left-alt:solid white 2.25pt;mso-border-left-themecolor:background1;
      background:gray;mso-background-themecolor:background1;mso-background-themeshade:
      128;padding:0cm 5.4pt 0cm 5.4pt" width="387" valign="top">
      <p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:
      3.0pt;margin-left:0cm;line-height:normal"><span style="font-family:&quot;Arial&quot;,sans-serif;
      color:white;mso-themecolor:background1">Gabriela Lira (Malvern Panalytical)<o:p></o:p></span></p>
      </td>
     </tr>
     <tr>
      <td style="width:63.8pt;border-top:none;border-left:none;
      border-bottom:solid white 2.25pt;mso-border-bottom-themecolor:background1;
      border-right:solid white 2.25pt;mso-border-right-themecolor:background1;
      mso-border-top-alt:solid white 2.25pt;mso-border-top-themecolor:background1;
      background:gray;mso-background-themecolor:background1;mso-background-themeshade:
      128;padding:0cm 5.4pt 0cm 5.4pt" width="85" valign="top">
      <p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:
      3.0pt;margin-left:0cm;line-height:normal"><span style="font-family:&quot;Arial&quot;,sans-serif;
      color:white;mso-themecolor:background1">9:30h<o:p></o:p></span></p>
      </td>
      <td style="width:14.0cm;border-top:none;border-left:
      none;border-bottom:solid white 2.25pt;mso-border-bottom-themecolor:background1;
      border-right:solid white 2.25pt;mso-border-right-themecolor:background1;
      mso-border-top-alt:solid white 2.25pt;mso-border-top-themecolor:background1;
      mso-border-left-alt:solid white 2.25pt;mso-border-left-themecolor:background1;
      background:gray;mso-background-themecolor:background1;mso-background-themeshade:
      128;padding:0cm 5.4pt 0cm 5.4pt" width="529" valign="top">
      <p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:
      3.0pt;margin-left:0cm;line-height:normal"><span style="font-family:&quot;Arial&quot;,sans-serif;
      color:white;mso-themecolor:background1">Soluções de automação de processos e
      laboratório<o:p></o:p></span></p>
      </td>
      <td style="width:290.6pt;border-top:none;border-left:
      none;border-bottom:solid white 2.25pt;mso-border-bottom-themecolor:background1;
      border-right:solid white 2.25pt;mso-border-right-themecolor:background1;
      mso-border-top-alt:solid white 2.25pt;mso-border-top-themecolor:background1;
      mso-border-left-alt:solid white 2.25pt;mso-border-left-themecolor:background1;
      background:gray;mso-background-themecolor:background1;mso-background-themeshade:
      128;padding:0cm 5.4pt 0cm 5.4pt" width="387" valign="top">
      <p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:
      3.0pt;margin-left:0cm;line-height:normal"><span style="font-family:&quot;Arial&quot;,sans-serif;
      color:white;mso-themecolor:background1">Diógenes Silva (Malvern Panalytical)<o:p></o:p></span></p>
      </td>
     </tr>
     <tr>
      <td style="width:63.8pt;border-top:none;border-left:none;
      border-bottom:solid white 2.25pt;mso-border-bottom-themecolor:background1;
      border-right:solid white 2.25pt;mso-border-right-themecolor:background1;
      mso-border-top-alt:solid white 2.25pt;mso-border-top-themecolor:background1;
      padding:0cm 5.4pt 0cm 5.4pt" width="85" valign="top">
      <p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:
      3.0pt;margin-left:0cm;line-height:normal"><b><span style="font-family:&quot;Arial&quot;,sans-serif">10:00h<o:p></o:p></span></b></p>
      </td>
      <td colspan="2" style="width:687.5pt;border-top:none;
      border-left:none;border-bottom:solid white 2.25pt;mso-border-bottom-themecolor:
      background1;border-right:solid white 2.25pt;mso-border-right-themecolor:background1;
      mso-border-top-alt:solid white 2.25pt;mso-border-top-themecolor:background1;
      mso-border-left-alt:solid white 2.25pt;mso-border-left-themecolor:background1;
      padding:0cm 5.4pt 0cm 5.4pt" width="917" valign="top">
      <p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;
      margin-bottom:3.0pt;margin-left:0cm;text-align:center;line-height:normal" align="center"><b><span style="font-family:&quot;Arial&quot;,sans-serif">Coffee
      break<o:p></o:p></span></b></p>
      </td>
     </tr>
     <tr>
      <td style="width:63.8pt;border-top:none;border-left:none;
      border-bottom:solid white 2.25pt;mso-border-bottom-themecolor:background1;
      border-right:solid white 2.25pt;mso-border-right-themecolor:background1;
      mso-border-top-alt:solid white 2.25pt;mso-border-top-themecolor:background1;
      background:gray;mso-background-themecolor:background1;mso-background-themeshade:
      128;padding:0cm 5.4pt 0cm 5.4pt" width="85" valign="top">
      <p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:
      3.0pt;margin-left:0cm;line-height:normal"><span style="font-family:&quot;Arial&quot;,sans-serif;
      color:white;mso-themecolor:background1">10:30h<o:p></o:p></span></p>
      </td>
      <td style="width:14.0cm;border-top:none;border-left:
      none;border-bottom:solid white 2.25pt;mso-border-bottom-themecolor:background1;
      border-right:solid white 2.25pt;mso-border-right-themecolor:background1;
      mso-border-top-alt:solid white 2.25pt;mso-border-top-themecolor:background1;
      mso-border-left-alt:solid white 2.25pt;mso-border-left-themecolor:background1;
      background:gray;mso-background-themecolor:background1;mso-background-themeshade:
      128;padding:0cm 5.4pt 0cm 5.4pt" width="529" valign="top">
      <p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:
      3.0pt;margin-left:0cm;line-height:normal"><span style="font-family:&quot;Arial&quot;,sans-serif;
      color:white;mso-themecolor:background1">Tamanho de partículas na mineração<o:p></o:p></span></p>
      </td>
      <td style="width:290.6pt;border-top:none;border-left:
      none;border-bottom:solid white 2.25pt;mso-border-bottom-themecolor:background1;
      border-right:solid white 2.25pt;mso-border-right-themecolor:background1;
      mso-border-top-alt:solid white 2.25pt;mso-border-top-themecolor:background1;
      mso-border-left-alt:solid white 2.25pt;mso-border-left-themecolor:background1;
      background:gray;mso-background-themecolor:background1;mso-background-themeshade:
      128;padding:0cm 5.4pt 0cm 5.4pt" width="387" valign="top">
      <p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:
      3.0pt;margin-left:0cm;line-height:normal"><span style="font-family:&quot;Arial&quot;,sans-serif;
      color:white;mso-themecolor:background1">Henrique Kajiyama (Malvern
      Panalytical) <o:p></o:p></span></p>
      </td>
     </tr>
     <tr>
      <td style="width:63.8pt;border-top:none;border-left:none;
      border-bottom:solid white 2.25pt;mso-border-bottom-themecolor:background1;
      border-right:solid white 2.25pt;mso-border-right-themecolor:background1;
      mso-border-top-alt:solid white 2.25pt;mso-border-top-themecolor:background1;
      background:gray;mso-background-themecolor:background1;mso-background-themeshade:
      128;padding:0cm 5.4pt 0cm 5.4pt" width="85" valign="top">
      <p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:
      3.0pt;margin-left:0cm;line-height:normal"><span style="font-family:&quot;Arial&quot;,sans-serif;
      color:white;mso-themecolor:background1">11:00h<o:p></o:p></span></p>
      </td>
      <td style="width:14.0cm;border-top:none;border-left:
      none;border-bottom:solid white 2.25pt;mso-border-bottom-themecolor:background1;
      border-right:solid white 2.25pt;mso-border-right-themecolor:background1;
      mso-border-top-alt:solid white 2.25pt;mso-border-top-themecolor:background1;
      mso-border-left-alt:solid white 2.25pt;mso-border-left-themecolor:background1;
      background:gray;mso-background-themecolor:background1;mso-background-themeshade:
      128;padding:0cm 5.4pt 0cm 5.4pt" width="529" valign="top">
      <p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:
      3.0pt;margin-left:0cm;line-height:normal"><span style="font-family:&quot;Arial&quot;,sans-serif;
      color:white;mso-themecolor:background1">Confiabilidade analítica através da
      aplicação de ferramentas de QA/QC e LIMS em laboratórios químicos<o:p></o:p></span></p>
      </td>
      <td style="width:290.6pt;border-top:none;border-left:
      none;border-bottom:solid white 2.25pt;mso-border-bottom-themecolor:background1;
      border-right:solid white 2.25pt;mso-border-right-themecolor:background1;
      mso-border-top-alt:solid white 2.25pt;mso-border-top-themecolor:background1;
      mso-border-left-alt:solid white 2.25pt;mso-border-left-themecolor:background1;
      background:gray;mso-background-themecolor:background1;mso-background-themeshade:
      128;padding:0cm 5.4pt 0cm 5.4pt" width="387" valign="top">
      <p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:
      3.0pt;margin-left:0cm;line-height:normal"><span style="font-family:&quot;Arial&quot;,sans-serif;
      color:white;mso-themecolor:background1">Saulo Colenci (LCT)<o:p></o:p></span></p>
      </td>
     </tr>
     <tr>
      <td style="width:63.8pt;border-top:none;border-left:none;
      border-bottom:solid white 2.25pt;mso-border-bottom-themecolor:background1;
      border-right:solid white 2.25pt;mso-border-right-themecolor:background1;
      mso-border-top-alt:solid white 2.25pt;mso-border-top-themecolor:background1;
      background:gray;mso-background-themecolor:background1;mso-background-themeshade:
      128;padding:0cm 5.4pt 0cm 5.4pt" width="85" valign="top">
      <p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:
      3.0pt;margin-left:0cm;line-height:normal"><span style="font-family:&quot;Arial&quot;,sans-serif;
      color:white;mso-themecolor:background1">11:30h<o:p></o:p></span></p>
      </td>
      <td style="width:14.0cm;border-top:none;border-left:
      none;border-bottom:solid white 2.25pt;mso-border-bottom-themecolor:background1;
      border-right:solid white 2.25pt;mso-border-right-themecolor:background1;
      mso-border-top-alt:solid white 2.25pt;mso-border-top-themecolor:background1;
      mso-border-left-alt:solid white 2.25pt;mso-border-left-themecolor:background1;
      background:gray;mso-background-themecolor:background1;mso-background-themeshade:
      128;padding:0cm 5.4pt 0cm 5.4pt" width="529" valign="top">
      <p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:
      3.0pt;margin-left:0cm;line-height:normal"><span style="font-family:&quot;Arial&quot;,sans-serif;
      color:white;mso-themecolor:background1">Estimativa da incerteza de medição na
      determinação de elementos maiores e menores em rochas silicáticas por<o:p></o:p></span></p>
      <p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:
      3.0pt;margin-left:0cm;line-height:normal"><span style="font-family:&quot;Arial&quot;,sans-serif;
      color:white;mso-themecolor:background1">espectrometria de fluorescência de
      raios X com resultados de controle de qualidade<o:p></o:p></span></p>
      </td>
      <td style="width:290.6pt;border-top:none;border-left:
      none;border-bottom:solid white 2.25pt;mso-border-bottom-themecolor:background1;
      border-right:solid white 2.25pt;mso-border-right-themecolor:background1;
      mso-border-top-alt:solid white 2.25pt;mso-border-top-themecolor:background1;
      mso-border-left-alt:solid white 2.25pt;mso-border-left-themecolor:background1;
      background:gray;mso-background-themecolor:background1;mso-background-themeshade:
      128;padding:0cm 5.4pt 0cm 5.4pt" width="387" valign="top">
      <p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:
      3.0pt;margin-left:0cm;line-height:normal"><span style="font-family:&quot;Arial&quot;,sans-serif;
      color:white;mso-themecolor:background1">Profa. Dra. Jacinta Enzweiler
      (Unicamp)<o:p></o:p></span></p>
      </td>
     </tr>
     <tr>
      <td style="width:63.8pt;border-top:none;border-left:none;
      border-bottom:solid white 2.25pt;mso-border-bottom-themecolor:background1;
      border-right:solid white 2.25pt;mso-border-right-themecolor:background1;
      mso-border-top-alt:solid white 2.25pt;mso-border-top-themecolor:background1;
      padding:0cm 5.4pt 0cm 5.4pt" width="85" valign="top">
      <p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:
      3.0pt;margin-left:0cm;line-height:normal"><b><span style="font-family:&quot;Arial&quot;,sans-serif">12:00h<o:p></o:p></span></b></p>
      </td>
      <td colspan="2" style="width:687.5pt;border-top:none;
      border-left:none;border-bottom:solid white 2.25pt;mso-border-bottom-themecolor:
      background1;border-right:solid white 2.25pt;mso-border-right-themecolor:background1;
      mso-border-top-alt:solid white 2.25pt;mso-border-top-themecolor:background1;
      mso-border-left-alt:solid white 2.25pt;mso-border-left-themecolor:background1;
      padding:0cm 5.4pt 0cm 5.4pt" width="917" valign="top">
      <p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;
      margin-bottom:3.0pt;margin-left:0cm;text-align:center;line-height:normal" align="center"><b><span style="font-family:&quot;Arial&quot;,sans-serif">Almoço<o:p></o:p></span></b></p>
      </td>
     </tr>
     <tr>
      <td style="width:63.8pt;border-top:none;border-left:none;
      border-bottom:solid white 2.25pt;mso-border-bottom-themecolor:background1;
      border-right:solid white 2.25pt;mso-border-right-themecolor:background1;
      mso-border-top-alt:solid white 2.25pt;mso-border-top-themecolor:background1;
      background:gray;mso-background-themecolor:background1;mso-background-themeshade:
      128;padding:0cm 5.4pt 0cm 5.4pt" width="85" valign="top">
      <p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:
      3.0pt;margin-left:0cm;line-height:normal"><span style="font-family:&quot;Arial&quot;,sans-serif;
      color:white;mso-themecolor:background1">13:30h<o:p></o:p></span></p>
      </td>
      <td style="width:14.0cm;border-top:none;border-left:
      none;border-bottom:solid white 2.25pt;mso-border-bottom-themecolor:background1;
      border-right:solid white 2.25pt;mso-border-right-themecolor:background1;
      mso-border-top-alt:solid white 2.25pt;mso-border-top-themecolor:background1;
      mso-border-left-alt:solid white 2.25pt;mso-border-left-themecolor:background1;
      background:gray;mso-background-themecolor:background1;mso-background-themeshade:
      128;padding:0cm 5.4pt 0cm 5.4pt" width="529" valign="top">
      <p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:
      3.0pt;margin-left:0cm;line-height:normal"><span style="font-family:&quot;Arial&quot;,sans-serif;
      color:white;mso-themecolor:background1">Estudo de caso na Mineração
      utilizando a técnica analítica de FRX<o:p></o:p></span></p>
      </td>
      <td style="width:290.6pt;border-top:none;border-left:
      none;border-bottom:solid white 2.25pt;mso-border-bottom-themecolor:background1;
      border-right:solid white 2.25pt;mso-border-right-themecolor:background1;
      mso-border-top-alt:solid white 2.25pt;mso-border-top-themecolor:background1;
      mso-border-left-alt:solid white 2.25pt;mso-border-left-themecolor:background1;
      background:gray;mso-background-themecolor:background1;mso-background-themeshade:
      128;padding:0cm 5.4pt 0cm 5.4pt" width="387" valign="top">
      <p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:
      3.0pt;margin-left:0cm;line-height:normal"><span style="font-family:&quot;Arial&quot;,sans-serif;
      color:white;mso-themecolor:background1">Gláucia Maria Longatti de Santana (AMG
      Mineração)<o:p></o:p></span></p>
      </td>
     </tr>
     <tr>
      <td style="width:63.8pt;border-top:none;border-left:none;
      border-bottom:solid white 2.25pt;mso-border-bottom-themecolor:background1;
      border-right:solid white 2.25pt;mso-border-right-themecolor:background1;
      mso-border-top-alt:solid white 2.25pt;mso-border-top-themecolor:background1;
      background:gray;mso-background-themecolor:background1;mso-background-themeshade:
      128;padding:0cm 5.4pt 0cm 5.4pt" width="85" valign="top">
      <p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:
      3.0pt;margin-left:0cm;line-height:normal"><span style="font-family:&quot;Arial&quot;,sans-serif;
      color:white;mso-themecolor:background1">14:00h<o:p></o:p></span></p>
      </td>
      <td style="width:14.0cm;border-top:none;border-left:
      none;border-bottom:solid white 2.25pt;mso-border-bottom-themecolor:background1;
      border-right:solid white 2.25pt;mso-border-right-themecolor:background1;
      mso-border-top-alt:solid white 2.25pt;mso-border-top-themecolor:background1;
      mso-border-left-alt:solid white 2.25pt;mso-border-left-themecolor:background1;
      background:gray;mso-background-themecolor:background1;mso-background-themeshade:
      128;padding:0cm 5.4pt 0cm 5.4pt" width="529" valign="top">
      <p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:
      3.0pt;margin-left:0cm;line-height:normal"><span style="font-family:&quot;Arial&quot;,sans-serif;
      color:white;mso-themecolor:background1">A importância do controle de
      qualidade mineralógico por DRX na mineração: exemplos de caulins e bauxitas (na
      Amazônia) <o:p></o:p></span></p>
      </td>
      <td style="width:290.6pt;border-top:none;border-left:
      none;border-bottom:solid white 2.25pt;mso-border-bottom-themecolor:background1;
      border-right:solid white 2.25pt;mso-border-right-themecolor:background1;
      mso-border-top-alt:solid white 2.25pt;mso-border-top-themecolor:background1;
      mso-border-left-alt:solid white 2.25pt;mso-border-left-themecolor:background1;
      background:gray;mso-background-themecolor:background1;mso-background-themeshade:
      128;padding:0cm 5.4pt 0cm 5.4pt" width="387" valign="top">
      <p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:
      3.0pt;margin-left:0cm;line-height:normal"><span style="font-family:&quot;Arial&quot;,sans-serif;
      color:white;mso-themecolor:background1">Prof. Rômulo Angélica &nbsp;(UFPA)<o:p></o:p></span></p>
      </td>
     </tr>
     <tr>
      <td style="width:63.8pt;border-top:none;border-left:none;
      border-bottom:solid white 2.25pt;mso-border-bottom-themecolor:background1;
      border-right:solid white 2.25pt;mso-border-right-themecolor:background1;
      mso-border-top-alt:solid white 2.25pt;mso-border-top-themecolor:background1;
      background:gray;mso-background-themecolor:background1;mso-background-themeshade:
      128;padding:0cm 5.4pt 0cm 5.4pt" width="85" valign="top">
      <p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:
      3.0pt;margin-left:0cm;line-height:normal"><span style="font-family:&quot;Arial&quot;,sans-serif;
      color:white;mso-themecolor:background1">14:30h<o:p></o:p></span></p>
      </td>
      <td style="width:14.0cm;border-top:none;border-left:
      none;border-bottom:solid white 2.25pt;mso-border-bottom-themecolor:background1;
      border-right:solid white 2.25pt;mso-border-right-themecolor:background1;
      mso-border-top-alt:solid white 2.25pt;mso-border-top-themecolor:background1;
      mso-border-left-alt:solid white 2.25pt;mso-border-left-themecolor:background1;
      background:gray;mso-background-themecolor:background1;mso-background-themeshade:
      128;padding:0cm 5.4pt 0cm 5.4pt" width="529" valign="top">
      <p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:
      3.0pt;margin-left:0cm;line-height:normal"><span style="font-family:&quot;Arial&quot;,sans-serif;
      color:white;mso-themecolor:background1">Análises por agrupamento por DRX para
      estudos de mineralogia quantitativa<o:p></o:p></span></p>
      </td>
      <td style="width:290.6pt;border-top:none;border-left:
      none;border-bottom:solid white 2.25pt;mso-border-bottom-themecolor:background1;
      border-right:solid white 2.25pt;mso-border-right-themecolor:background1;
      mso-border-top-alt:solid white 2.25pt;mso-border-top-themecolor:background1;
      mso-border-left-alt:solid white 2.25pt;mso-border-left-themecolor:background1;
      background:gray;mso-background-themecolor:background1;mso-background-themeshade:
      128;padding:0cm 5.4pt 0cm 5.4pt" width="387" valign="top">
      <p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:
      3.0pt;margin-left:0cm;line-height:normal"><span style="font-family:&quot;Arial&quot;,sans-serif;
      color:white;mso-themecolor:background1">Renato Contessotto &nbsp;(LCT)<o:p></o:p></span></p>
      </td>
     </tr>
     <tr>
      <td style="width:63.8pt;border-top:none;border-left:none;
      border-bottom:solid white 2.25pt;mso-border-bottom-themecolor:background1;
      border-right:solid white 2.25pt;mso-border-right-themecolor:background1;
      mso-border-top-alt:solid white 2.25pt;mso-border-top-themecolor:background1;
      padding:0cm 5.4pt 0cm 5.4pt" width="85" valign="top">
      <p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:
      3.0pt;margin-left:0cm;line-height:normal"><b><span style="font-family:&quot;Arial&quot;,sans-serif">15:00h<o:p></o:p></span></b></p>
      </td>
      <td colspan="2" style="width:687.5pt;border-top:none;
      border-left:none;border-bottom:solid white 2.25pt;mso-border-bottom-themecolor:
      background1;border-right:solid white 2.25pt;mso-border-right-themecolor:background1;
      mso-border-top-alt:solid white 2.25pt;mso-border-top-themecolor:background1;
      mso-border-left-alt:solid white 2.25pt;mso-border-left-themecolor:background1;
      padding:0cm 5.4pt 0cm 5.4pt" width="917" valign="top">
      <p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;
      margin-bottom:3.0pt;margin-left:0cm;text-align:center;line-height:normal" align="center"><b><span style="font-family:&quot;Arial&quot;,sans-serif">Coffee
      break<o:p></o:p></span></b></p>
      </td>
     </tr>
     <tr>
      <td style="width:63.8pt;border-top:none;border-left:none;
      border-bottom:solid white 2.25pt;mso-border-bottom-themecolor:background1;
      border-right:solid white 2.25pt;mso-border-right-themecolor:background1;
      mso-border-top-alt:solid white 2.25pt;mso-border-top-themecolor:background1;
      background:gray;mso-background-themecolor:background1;mso-background-themeshade:
      128;padding:0cm 5.4pt 0cm 5.4pt" width="85" valign="top">
      <p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:
      3.0pt;margin-left:0cm;line-height:normal"><span style="font-family:&quot;Arial&quot;,sans-serif;
      color:white;mso-themecolor:background1">15:30h<o:p></o:p></span></p>
      </td>
      <td style="width:14.0cm;border-top:none;border-left:
      none;border-bottom:solid white 2.25pt;mso-border-bottom-themecolor:background1;
      border-right:solid white 2.25pt;mso-border-right-themecolor:background1;
      mso-border-top-alt:solid white 2.25pt;mso-border-top-themecolor:background1;
      mso-border-left-alt:solid white 2.25pt;mso-border-left-themecolor:background1;
      background:gray;mso-background-themecolor:background1;mso-background-themeshade:
      128;padding:0cm 5.4pt 0cm 5.4pt" width="529" valign="top">
      <p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:
      3.0pt;margin-left:0cm;line-height:normal"><span style="font-family:&quot;Arial&quot;,sans-serif;
      color:white;mso-themecolor:background1">Microtomografia 3D aplicada ao setor
      mínerometalúrgico<o:p></o:p></span></p>
      </td>
      <td style="width:290.6pt;border-top:none;border-left:
      none;border-bottom:solid white 2.25pt;mso-border-bottom-themecolor:background1;
      border-right:solid white 2.25pt;mso-border-right-themecolor:background1;
      mso-border-top-alt:solid white 2.25pt;mso-border-top-themecolor:background1;
      mso-border-left-alt:solid white 2.25pt;mso-border-left-themecolor:background1;
      background:gray;mso-background-themecolor:background1;mso-background-themeshade:
      128;padding:0cm 5.4pt 0cm 5.4pt" width="387" valign="top">
      <p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:
      3.0pt;margin-left:0cm;line-height:normal"><span style="font-family:&quot;Arial&quot;,sans-serif;
      color:white;mso-themecolor:background1">Profa. Dra. Carina Ulsen / Daniel
      Uliana (LCT)<o:p></o:p></span></p>
      </td>
     </tr>
     <tr>
      <td style="width:63.8pt;border-top:none;border-left:none;
      border-bottom:solid white 2.25pt;mso-border-bottom-themecolor:background1;
      border-right:solid white 2.25pt;mso-border-right-themecolor:background1;
      mso-border-top-alt:solid white 2.25pt;mso-border-top-themecolor:background1;
      background:gray;mso-background-themecolor:background1;mso-background-themeshade:
      128;padding:0cm 5.4pt 0cm 5.4pt" width="85" valign="top">
      <p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:
      3.0pt;margin-left:0cm;line-height:normal"><span style="font-family:&quot;Arial&quot;,sans-serif;
      color:white;mso-themecolor:background1">16:00h<o:p></o:p></span></p>
      </td>
      <td style="width:14.0cm;border-top:none;border-left:
      none;border-bottom:solid white 2.25pt;mso-border-bottom-themecolor:background1;
      border-right:solid white 2.25pt;mso-border-right-themecolor:background1;
      mso-border-top-alt:solid white 2.25pt;mso-border-top-themecolor:background1;
      mso-border-left-alt:solid white 2.25pt;mso-border-left-themecolor:background1;
      background:gray;mso-background-themecolor:background1;mso-background-themeshade:
      128;padding:0cm 5.4pt 0cm 5.4pt" width="529" valign="top">
      <p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:
      3.0pt;margin-left:0cm;line-height:normal"><span style="font-family:&quot;Arial&quot;,sans-serif;
      color:white;mso-themecolor:background1">Novas soluções para aumentar a
      eficiência do processamento de minério e minerais<o:p></o:p></span></p>
      </td>
      <td style="width:290.6pt;border-top:none;border-left:
      none;border-bottom:solid white 2.25pt;mso-border-bottom-themecolor:background1;
      border-right:solid white 2.25pt;mso-border-right-themecolor:background1;
      mso-border-top-alt:solid white 2.25pt;mso-border-top-themecolor:background1;
      mso-border-left-alt:solid white 2.25pt;mso-border-left-themecolor:background1;
      background:gray;mso-background-themecolor:background1;mso-background-themeshade:
      128;padding:0cm 5.4pt 0cm 5.4pt" width="387" valign="top">
      <p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:
      3.0pt;margin-left:0cm;line-height:normal"><span style="font-family:&quot;Arial&quot;,sans-serif;
      color:white;mso-themecolor:background1">Uwe König (Malvern Panalytical)<o:p></o:p></span></p>
      </td>
     </tr>
     <tr>
      <td style="width:63.8pt;border-top:none;border-left:none;
      border-bottom:solid white 2.25pt;mso-border-bottom-themecolor:background1;
      border-right:solid white 2.25pt;mso-border-right-themecolor:background1;
      mso-border-top-alt:solid white 2.25pt;mso-border-top-themecolor:background1;
      background:gray;mso-background-themecolor:background1;mso-background-themeshade:
      128;padding:0cm 5.4pt 0cm 5.4pt" width="85" valign="top">
      <p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:
      3.0pt;margin-left:0cm;line-height:normal"><span style="font-family:&quot;Arial&quot;,sans-serif;
      color:white;mso-themecolor:background1">16:30h<o:p></o:p></span></p>
      </td>
      <td style="width:14.0cm;border-top:none;border-left:
      none;border-bottom:solid white 2.25pt;mso-border-bottom-themecolor:background1;
      border-right:solid white 2.25pt;mso-border-right-themecolor:background1;
      mso-border-top-alt:solid white 2.25pt;mso-border-top-themecolor:background1;
      mso-border-left-alt:solid white 2.25pt;mso-border-left-themecolor:background1;
      background:gray;mso-background-themecolor:background1;mso-background-themeshade:
      128;padding:0cm 5.4pt 0cm 5.4pt" width="529" valign="top">
      <p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:
      3.0pt;margin-left:0cm;line-height:normal"><span style="font-family:&quot;Arial&quot;,sans-serif;
      color:white;mso-themecolor:background1">Encerramento<o:p></o:p></span></p>
      </td>
      <td style="width:290.6pt;border-top:none;border-left:
      none;border-bottom:solid white 2.25pt;mso-border-bottom-themecolor:background1;
      border-right:solid white 2.25pt;mso-border-right-themecolor:background1;
      mso-border-top-alt:solid white 2.25pt;mso-border-top-themecolor:background1;
      mso-border-left-alt:solid white 2.25pt;mso-border-left-themecolor:background1;
      background:gray;mso-background-themecolor:background1;mso-background-themeshade:
      128;padding:0cm 5.4pt 0cm 5.4pt" width="387" valign="top">
      <p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:
      3.0pt;margin-left:0cm;line-height:normal"><span style="font-family:&quot;Arial&quot;,sans-serif;
      color:white;mso-themecolor:background1">LCT / Malvern Panalytical<o:p></o:p></span></p>
      </td>
     </tr>
     <tr>
      <td style="width:63.8pt;border-top:none;border-left:none;
      border-bottom:solid white 2.25pt;mso-border-bottom-themecolor:background1;
      border-right:solid white 2.25pt;mso-border-right-themecolor:background1;
      mso-border-top-alt:solid white 2.25pt;mso-border-top-themecolor:background1;
      background:gray;mso-background-themecolor:background1;mso-background-themeshade:
      128;padding:0cm 5.4pt 0cm 5.4pt" width="85" valign="top">
      <p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:
      3.0pt;margin-left:0cm;line-height:normal"><span style="font-family:&quot;Arial&quot;,sans-serif;
      color:white;mso-themecolor:background1">16:45h<o:p></o:p></span></p>
      </td>
      <td style="width:14.0cm;border-top:none;border-left:
      none;border-bottom:solid white 2.25pt;mso-border-bottom-themecolor:background1;
      border-right:solid white 2.25pt;mso-border-right-themecolor:background1;
      mso-border-top-alt:solid white 2.25pt;mso-border-top-themecolor:background1;
      mso-border-left-alt:solid white 2.25pt;mso-border-left-themecolor:background1;
      background:gray;mso-background-themecolor:background1;mso-background-themeshade:
      128;padding:0cm 5.4pt 0cm 5.4pt" width="529" valign="top">
      <p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:
      3.0pt;margin-left:0cm;line-height:normal"><span style="font-family:&quot;Arial&quot;,sans-serif;
      color:white;mso-themecolor:background1">Visita ao laboratório<o:p></o:p></span></p>
      </td>
      <td style="width:290.6pt;border-top:none;border-left:
      none;border-bottom:solid white 2.25pt;mso-border-bottom-themecolor:background1;
      border-right:solid white 2.25pt;mso-border-right-themecolor:background1;
      mso-border-top-alt:solid white 2.25pt;mso-border-top-themecolor:background1;
      mso-border-left-alt:solid white 2.25pt;mso-border-left-themecolor:background1;
      background:gray;mso-background-themecolor:background1;mso-background-themeshade:
      128;padding:0cm 5.4pt 0cm 5.4pt" width="387" valign="top">
      <p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:
      3.0pt;margin-left:0cm;line-height:normal"><span style="font-family:&quot;Arial&quot;,sans-serif;
      color:white;mso-themecolor:background1">LCT / Malvern Panalytical<o:p></o:p></span></p>
      </td>
     </tr>
    </tbody></table><p class="MsoNormal" style="text-align: left; margin: 18pt 0cm; line-height: 18pt;"><span style="font-size: 12pt; font-family: Arial, sans-serif; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;"><o:p></o:p></span></p>`,
    created: "2019-10-23T18:39:00.000-03:00",
    modified: "2019-10-23T18:39:00.000-03:00",
  },
  {
    id: 32,
    title: "Centros Multiusuários",
    imgSrc: "/img/tempdata/articles/32.jpg",
    description:
      "O LCT participou da reportagem do G1-Globo como um dos Centros Multiusuários mais ativos da USP.",
    body: `<p><span style="font-size: 10.5pt; line-height: 107%; font-family: &quot;Segoe UI&quot;, sans-serif; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;"><span style="font-family: Helvetica;">O
    LCT participou da reportagem do G1-Globo como um dos Centros Multiusuários mais
    ativos da USP. A matéria se refere a Resolução USP 7.661/2019 publicada pela
    Reitoria da USP que regulamenta os critérios para o compartilhamento e
    permissão de uso da infraestrutura, equipamentos e demais instalações da
    Universidade. Conheça mais da infraestrutura disponível para compartilhamento
    em nosso site – </span></span><a href="http://www.lct.poli.usp.br."><span style="font-family: Helvetica;">www.lct.poli.usp.br.</span></a></p><p><a href="http://www.lct.poli.usp.br."><span style="font-family: Helvetica;"><br></span></a><span style="font-family: Helvetica;">Vídeo: </span><a href="https://www.linkedin.com/feed/update/urn:li:activity:6565308180954394624/"><span style="font-family: Helvetica;"><span style="font-family: Helvetica;">https://www.linkedin.com/feed/update/urn:li:activity:6565308180954394624/</span><br></span></a><o:p><span style="font-family: Helvetica;"><span style="font-family: Helvetica;">&nbsp;</span></span></o:p></p><p><o:p><span style="font-family: Helvetica;">&nbsp;</span></o:p></p><p class="MsoNormal"><o:p></o:p></p><p><span style="font-family: Helvetica;">
    
    </span><span style="font-family: Helvetica;">
    
    </span><span style="font-family: Helvetica;">
    
    </span></p>`,
    created: "2019-09-20T09:00:00.000-03:00",
    modified: "2019-09-20T09:00:00.000-03:00",
  },
  {
    id: 31,
    title:
      "Publicação da Brasil Mineral: “A Contribuição do LCT para a indústria mineral”",
    imgSrc: "/img/tempdata/articles/31.jpg",
    description:
      "A importância da caracterização tecnológica nas diversas etapas de um empreendimento mineiro e uma visão de futuro do LCT foram abordadas em matéria publicada pela Brasil Mineral.",
    body: `<h4><p class="MsoNormal" style="margin-top:18.0pt;margin-right:0cm;margin-bottom:
    18.0pt;margin-left:0cm;line-height:18.0pt;mso-line-height-rule:exactly"><span style="font-size: 12pt; font-family: Arial, sans-serif; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">Em
    matéria publicada pela revista Brasil Mineral, edição de Agosto/19, foi
    relatada a contribuição da Caracterização Tecnológica para a indústria mineral
    e, particularmente do LCT em seus quase 30 anos de existência. Profissionais
    renomados como o ex-secretário do Ministério de Minas e Energia - Eng. Vicente
    Lobo, o Prof. Henrique Kahn e a Dra. Maria Manuela Tassinari relataram a
    importância da área nas diversas etapas de um empreendimento mineiro. <o:p></o:p></span></p><p class="MsoNormal" style="margin-top:18.0pt;margin-right:0cm;margin-bottom:
    18.0pt;margin-left:0cm;line-height:18.0pt;mso-line-height-rule:exactly"><span style="font-size: 12pt; font-family: Arial, sans-serif; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">A
    Profa. Carina Ulsen trouxe uma abordagem de futuro ao relatar as ações que o
    LCT tem realizando para reduzir a lacuna entre o setor produtivo e a
    Universidade por meio do conceito de Centro Multiusuário, em consonância com as
    diretrizes da Universidade, assim como os avanços do laboratório em termos de
    qualidade, LIMS e a instalação de novas tecnologias. <o:p></o:p></span></p><p class="MsoNormal" style="margin-bottom:0cm;margin-bottom:.0001pt;line-height:
    18.0pt;mso-line-height-rule:exactly"><span style="font-size: 12pt; font-family: Arial, sans-serif; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">Clique no link abaixo para ler a matéria
    na íntegra (pág 28 a 30):<o:p></o:p></span></p><p class="MsoNormal" style="line-height:150%">
    
    
    
    
    
    </p><p class="MsoNormal" style="margin-bottom:0cm;margin-bottom:.0001pt;line-height:
    18.0pt;mso-line-height-rule:exactly"><span class="MsoHyperlink"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><a href="http://www.brasilmineral.com.br/revista/393/">http://www.brasilmineral.com.br/revista/393/</a></span></span><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><o:p></o:p></span></p></h4><p><span style="font-family: Helvetica;">
    
    
    
    
    
    </span></p><p class="MsoNormal"><o:p></o:p></p>`,
    created: "2019-09-13T09:05:00.000-03:00",
    modified: "2019-09-13T09:05:00.000-03:00",
  },
];

export default articles;
