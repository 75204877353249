




















































import Vue from "vue";

import DialogImage from "@/components/DialogImage.vue";

export default Vue.extend({
  name: "InfraestruturaMla",
  components: {
    DialogImage,
  },
  data: () => ({
    exemplos: [
      {
        imgSrc: "/img/infraestrutura/mla/aplicacao_01.png",
        thumbnailSrc: "/img/infraestrutura/mla/thumb_aplicacao_01.png",
        caption: "Exemplo 01",
      },
      {
        imgSrc: "/img/infraestrutura/mla/aplicacao_02.png",
        thumbnailSrc: "/img/infraestrutura/mla/thumb_aplicacao_02.png",
        caption: "Exemplo 02",
      },
      {
        imgSrc: "/img/infraestrutura/mla/aplicacao_03.jpg",
        thumbnailSrc: "/img/infraestrutura/mla/thumb_aplicacao_03.jpg",
        caption: "Exemplo 03",
      },
      {
        imgSrc: "/img/infraestrutura/mla/aplicacao_04.jpg",
        thumbnailSrc: "/img/infraestrutura/mla/thumb_aplicacao_04.jpg",
        caption: "Exemplo 04",
      },
      {
        imgSrc: "/img/infraestrutura/mla/aplicacao_05.png",
        thumbnailSrc: "/img/infraestrutura/mla/thumb_aplicacao_05.png",
        caption: "Exemplo 05",
      },
      {
        imgSrc: "/img/infraestrutura/mla/aplicacao_06.png",
        thumbnailSrc: "/img/infraestrutura/mla/thumb_aplicacao_06.png",
        caption: "Exemplo 06",
      },
      {
        imgSrc: "/img/infraestrutura/mla/aplicacao_07.png",
        thumbnailSrc: "/img/infraestrutura/mla/thumb_aplicacao_07.png",
        caption: "Exemplo 07",
      },
    ],
  }),
});
