import { RouteConfig } from "vue-router";

import MetodosAas from "../views/metodos/MetodosAas.vue";
import MetodosAreia from "../views/metodos/MetodosAreia.vue";
import MetodosAvu from "../views/metodos/MetodosAvu.vue";
import MetodosBauxita from "../views/metodos/MetodosBauxita.vue";
import MetodosCalcarios from "../views/metodos/MetodosCalcarios.vue";
import MetodosCimento from "../views/metodos/MetodosCimento.vue";
import MetodosFosfatos from "../views/metodos/MetodosFosfatos.vue";
import MetodosFrx from "../views/metodos/MetodosFrx.vue";
import MetodosIcp from "../views/metodos/MetodosIcp.vue";
import MetodosMineriosCobre from "../views/metodos/MetodosMineriosCobre.vue";
import MetodosMineriosEstanho from "../views/metodos/MetodosMineriosEstanho.vue";
import MetodosMineriosFerro from "../views/metodos/MetodosMineriosFerro.vue";
import MetodosMineriosManganes from "../views/metodos/MetodosMineriosManganes.vue";
import MetodosMineriosOuro from "../views/metodos/MetodosMineriosOuro.vue";
import MetodosRochas from "../views/metodos/MetodosRochas.vue";
import MetodosTerrasRaras from "../views/metodos/MetodosTerrasRaras.vue";

const routes: RouteConfig[] = [
  {
    path: "/metodos/aas",
    name: "MetodosAas",
    component: MetodosAas,
    meta: {
      title: "Métodos - Absorção atômica",
    },
  },
  {
    path: "/metodos/areia",
    name: "MetodosAreia",
    component: MetodosAreia,
    meta: {
      title: "Métodos - Areia",
    },
  },
  {
    path: "/metodos/avu",
    name: "MetodosAvu",
    component: MetodosAvu,
    meta: {
      title: "Métodos - Via úmida",
    },
  },
  {
    path: "/metodos/bauxita",
    name: "MetodosBauxita",
    component: MetodosBauxita,
    meta: {
      title: "Métodos - Bauxita",
    },
  },
  {
    path: "/metodos/calcarios",
    name: "MetodosCalcarios",
    component: MetodosCalcarios,
    meta: {
      title: "Métodos - Calcários",
    },
  },
  {
    path: "/metodos/cimento",
    name: "MetodosCimento",
    component: MetodosCimento,
    meta: {
      title: "Métodos - Cimento, agregados e concreto",
    },
  },
  {
    path: "/metodos/fosfatos",
    name: "MetodosFosfatos",
    component: MetodosFosfatos,
    meta: {
      title: "Métodos - Fosfatos",
    },
  },
  {
    path: "/metodos/frx",
    name: "MetodosFrx",
    component: MetodosFrx,
    meta: {
      title: "Métodos - Fluorescência de raios X",
    },
  },
  {
    path: "/metodos/icp",
    name: "MetodosIcp",
    component: MetodosIcp,
    meta: {
      title: "Métodos - ICP OES",
    },
  },
  {
    path: "/metodos/minerios-cobre",
    name: "MetodosMineriosCobre",
    component: MetodosMineriosCobre,
    meta: {
      title: "Métodos - Minérios e concentrados de cobre",
    },
  },
  {
    path: "/metodos/minerios-estanho",
    name: "MetodosMineriosEstanho",
    component: MetodosMineriosEstanho,
    meta: {
      title: "Métodos - Minérios de estanho, nóbio e tântalo",
    },
  },
  {
    path: "/metodos/minerios-ferro",
    name: "MetodosMineriosFerro",
    component: MetodosMineriosFerro,
    meta: {
      title: "Métodos - Minérios de ferro",
    },
  },
  {
    path: "/metodos/minerios-manganes",
    name: "MetodosMineriosManganes",
    component: MetodosMineriosManganes,
    meta: {
      title: "Métodos - Minérios de manganês",
    },
  },
  {
    path: "/metodos/minerios-ouro",
    name: "MetodosMineriosOuro",
    component: MetodosMineriosOuro,
    meta: {
      title: "Métodos - Minérios de ouro",
    },
  },
  {
    path: "/metodos/rochas",
    name: "MetodosRochas",
    component: MetodosRochas,
    meta: {
      title: "Métodos - Rochas e solos",
    },
  },
  {
    path: "/metodos/terras-raras",
    name: "MetodosTerrasRaras",
    component: MetodosTerrasRaras,
    meta: {
      title: "Métodos - Terras raras",
    },
  },
];

export default routes;
