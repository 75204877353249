



















import Vue from "vue";
import { DateTime } from "luxon";
import { find } from "lodash";

import articles from "@/tempdata/articles";

interface Article {
  id: number;
  title: string;
  imgSrc: string;
  description: string;
  body: string;
  created: string;
  modified: string;
}

export default Vue.extend({
  name: "Noticia",
  data: () => ({
    noticia: undefined as Article | undefined,
  }),
  computed: {
    noticiaId() {
      return Number(this.$route.params.noticiaId) || 0;
    },
    dataBr() {
      if (!this.noticia?.created) return "";
      const dateTime = DateTime.fromISO(this.noticia.created, {
        locale: "pt-BR",
      });
      return dateTime.toLocaleString({
        year: "numeric",
        month: "long",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      });
    },
    maxHeight() {
      if (this.$vuetify.breakpoint.xsOnly) return "100%";
      if (this.$vuetify.breakpoint.smAndDown) return "200";
      if (this.$vuetify.breakpoint.mdAndDown) return "300";
      if (this.$vuetify.breakpoint.lgAndDown) return "400";
      return "500";
    },
  },
  beforeRouteEnter(to, from, next) {
    if (Number(to?.params?.noticiaId) === 40) {
      window.open("/files/Vaga_LCT-Tecnico-Lab_Mineral_USP.pdf");
    } else {
      next();
    }
  },
  mounted() {
    this.noticia = find(articles, (article) => article.id === this.noticiaId);
    if (!this.noticia) {
      this.$router.push({ name: "Home" });
    }
  },
});
