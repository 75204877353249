import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import Contato from "../views/Contato.vue";
import Ensino from "../views/Ensino.vue";
import Home from "../views/Home.vue";
import Multiusuario from "../views/Multiusuario.vue";
import NaoEncontrado from "../views/NaoEncontrado.vue";
import Noticia from "../views/Noticia.vue";
import Pesquisa from "../views/Pesquisa.vue";
import SobreLCT from "../views/sobre/SobreLCT.vue";
import SobrePessoal from "../views/sobre/SobrePessoal.vue";

import routesInfraestrutura from "./infraestrutura";
import routesMetodos from "./metodos";
import routesServicos from "./servicos";

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "Laboratório de Caracterização Tecnológica",
    },
  },
  {
    path: "/noticia/:noticiaId",
    name: "Noticia",
    component: Noticia,
    meta: {
      title: "Notícia",
    },
  },
  {
    path: "/sobre/lct",
    name: "SobreLCT",
    component: SobreLCT,
    meta: {
      title: "Sobre",
    },
  },
  {
    path: "/sobre/pessoal",
    name: "SobrePessoal",
    component: SobrePessoal,
    meta: {
      title: "Pessoal",
    },
  },
  {
    path: "/ensino",
    name: "Ensino",
    component: Ensino,
    meta: {
      title: "Ensino",
    },
  },
  {
    path: "/pesquisa",
    name: "Pesquisa",
    component: Pesquisa,
    meta: {
      title: "Pesquisa",
    },
  },
  {
    path: "/multiusuario",
    name: "Multiusuario",
    component: Multiusuario,
    meta: {
      title: "Multiusuário",
    },
  },
  ...routesServicos,
  ...routesMetodos,
  ...routesInfraestrutura,
  {
    path: "/contato",
    name: "Contato",
    component: Contato,
    meta: {
      title: "Contato",
    },
  },
  {
    path: "*",
    name: "NaoEncontrado",
    component: NaoEncontrado,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
