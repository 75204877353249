








































































































































import Vue from "vue";
import { Route } from "vue-router";

export default Vue.extend({
  name: "App",
  data: () => ({
    drawer: false,
    menu: [
      { title: "Página inicial", to: { name: "Home" }, xsOnly: true },
      {
        title: "Sobre",
        children: [
          { title: "O LCT", to: { name: "SobreLCT" } },
          { title: "Pessoal", to: { name: "SobrePessoal" } },
        ],
      },
      { title: "Ensino", to: { name: "Ensino" } },
      { title: "Pesquisa", to: { name: "Pesquisa" } },
      { title: "Multiusuário", to: { name: "Multiusuario" } },
      {
        title: "Serviços",
        children: [
          {
            title: "Caracterização tecnológica",
            to: { name: "ServicosCaracterizacao" },
          },
          { title: "Análises", to: { name: "ServicosAnalises" } },
          { title: "Cursos", to: { name: "ServicosCursos" } },
          {
            title: "Assessorias e treinamentos",
            to: { name: "ServicosAssessorias" },
          },
          { title: "Software LIMS", to: { name: "ServicosLims" } },
        ],
      },
      { title: "Infraestrutura", to: { name: "Infraestrutura" } },
      { title: "Contato", to: { name: "Contato" } },
    ],
  }),
  created() {
    this.updateTitle(this.$route);
  },
  watch: {
    $route(to) {
      this.updateTitle(to);
    },
  },
  methods: {
    updateTitle(route: Route) {
      document.title = `LCT - ${
        route.meta?.title
          ? route.meta?.title
          : "Laboratório de Caracterização Tecnológica"
      }`;
    },
  },
});
