










































































































































































































































import Vue from "vue";

import CustomImage from "@/components/CustomImage.vue";
import DialogImage from "@/components/DialogImage.vue";

export default Vue.extend({
  name: "InfraestruturaMev",
  components: {
    CustomImage,
    DialogImage,
  },
  data: () => ({
    exemplos: [
      {
        imgSrc: "/img/infraestrutura/mev/aplicacao_01.png",
        thumbnailSrc: "/img/infraestrutura/mev/thumb_aplicacao_01.png",
        description: "Morfologia (Elétrons Secundários -SE)",
        caption: "Exemplo 01",
      },
      {
        imgSrc: "/img/infraestrutura/mev/aplicacao_02.png",
        thumbnailSrc: "/img/infraestrutura/mev/thumb_aplicacao_02.png",
        description:
          "Identificação de minerais por BSE (Backscattering - contraste de número atómico)",
        caption: "Exemplo 02",
      },
      {
        imgSrc: "/img/infraestrutura/mev/aplicacao_03.png",
        thumbnailSrc: "/img/infraestrutura/mev/thumb_aplicacao_03.png",
        description: "Catôdo Luminescência (CL - RGB)",
        caption: "Exemplo 03",
      },
      {
        imgSrc: "/img/infraestrutura/mev/aplicacao_04.jpg",
        thumbnailSrc: "/img/infraestrutura/mev/thumb_aplicacao_04.jpg",
        description: "Point ID – Microanálise química por EDS",
        caption: "Exemplo 04",
      },
      {
        imgSrc: "/img/infraestrutura/mev/aplicacao_05.jpg",
        thumbnailSrc: "/img/infraestrutura/mev/thumb_aplicacao_05.jpg",
        description:
          "Line Scan – Quantificação composicional de um perfil por EDS",
        caption: "Exemplo 05",
      },
      {
        imgSrc: "/img/infraestrutura/mev/aplicacao_06.jpg",
        thumbnailSrc: "/img/infraestrutura/mev/thumb_aplicacao_06.jpg",
        description:
          "Mapeamento Elementar por EDS mostrando a concentração dos elementos por falsa cor",
        caption: "Exemplo 06",
      },
    ],
    equipamentos: [
      {
        imgSrc: "/img/infraestrutura/mev/baltec_med020.jpg",
        imgAlt: "Imagem Baltec MED020",
        caption: "Recobridor de amostras MED020 (Bal-tec)",
      },
      {
        imgSrc: "/img/infraestrutura/mev/baltec_scd050.jpg",
        imgAlt: "Imagem Baltec SCD050",
        caption: "Recobridor de amostras SCD050 (Bal-tec)",
      },
      {
        imgSrc: "/img/infraestrutura/mev/leica_tic020.jpg",
        imgAlt: "Imagem Leica TIC020",
        caption: "Preparador de amostras TIC020 (Leica)",
      },
      {
        imgSrc: "/img/infraestrutura/mev/corte.jpg",
        imgAlt: "Imagem corte",
        caption: "Máquina de corte Accutom-50 (Struers)",
      },
      {
        imgSrc: "/img/infraestrutura/mev/polimento.jpg",
        imgAlt: "Imagem desbaste e polimento",
        caption: "Sistema de desbaste e polimento Tegramin-30 (Struers)",
      },
    ],
  }),
});
