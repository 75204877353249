














































































































import Vue from "vue";
import { mask } from "vue-the-mask";

import Destaques from "@/components/Destaques.vue";
// import Noticias from "@/components/Noticias.vue";

export default Vue.extend({
  name: "Home",
  components: {
    Destaques,
    // Noticias,
  },
  directives: {
    mask,
  },
  data: () => ({
    codigoAutenticidade: "",
    cards: [
      {
        imgSrc: "img/home/sobre.jpg",
        title: "Sobre",
        description:
          "O LCT é um centro de pesquisa focado em caracterização de matérias-primas minerais e materiais. Com quase 30 anos de experiência, oferece infraestrutura e expertise em projetos de P&D, atividades acadêmicas e de extensão, buscando o uso sustentável dos recursos minerais.",
        to: { name: "SobreLCT" },
      },
      {
        imgSrc: "img/home/multiusuario.jpg",
        title: "Multiusuário",
        description:
          "Disponibilizamos aos docentes e pesquisadores do país e do exterior, independentemente da instituição a que estejam vinculados, o acesso à nossa infraestrutura, possibilitando a realização de análises, ensaios e uso dos equipamentos existentes.",
        to: { name: "Multiusuario" },
      },
      {
        imgSrc: "img/home/caracterizacao.jpg",
        title: "Caracterização",
        description:
          "A caracterização tecnológica de matérias-primas minerais estuda suas propriedades para otimizar processos de beneficiamento. Reduz custos e prazos no desenvolvimento sustentável de empreendimentos mineiros, abrangendo desde pesquisa mineral até o reaproveitamento de resíduos.",
        to: { name: "ServicosCaracterizacao" },
      },
      {
        imgSrc: "img/home/analises.jpg",
        title: "Análises",
        description:
          "Oferecemos uma variade de análises laboratoriais incluindo granulometria, mineralogia, análises químicas, difratometria de raios X, microscopia óptica e eletrônica, análise de partículas e densidade, com resultados precisos e de alta qualidade.",
        to: { name: "ServicosAnalises" },
      },
      {
        imgSrc: "img/home/infraestrutura.jpg",
        title: "Infraestrutura",
        description:
          "A infraestrutura do laboratório inclui equipamentos avançados de microscopia, análise química, medição de partículas, porosidade e separações minerais, permitindo oferecer serviços de alta qualidade para empresas e instituições de pesquisa.",
        to: { name: "Infraestrutura" },
      },
      {
        imgSrc: "img/home/contato.jpg",
        title: "Contato",
        description:
          "Para entrar em contato conosco, utilize as informações de telefone e e-mail disponíveis clicando aqui. Estamos à disposição para atender às suas necessidades e fornecer mais detalhes sobre nossos serviços.",
        to: { name: "Contato" },
      },
    ],
    universidades: [
      {
        href: "http://www5.usp.br/",
        imgSrc: "/img/universidade/usp.jpg",
        imgAlt: "Universidade de São Paulo",
      },
      {
        href: "http://www.poli.usp.br/",
        imgSrc: "/img/universidade/poli.jpg",
        imgAlt: "Escola Politécnica da Universidade de São Paulo",
      },
      {
        href: "http://www.pmi.poli.usp.br/",
        imgSrc: "/img/universidade/pmi.jpg",
        imgAlt: "Depto. Eng. de Minas e de Petróleo",
      },
    ],
    apoio: [
      {
        href: "http://www.cnpq.br/",
        imgSrc: "/img/apoio/cnpq.jpg",
        imgAlt: "CNPq",
      },
      {
        href: "http://www.cnpq.br/",
        imgSrc: "/img/apoio/fapesp.jpg",
        imgAlt: "Fapesp",
      },
      {
        href: "http://www.finep.gov.br/",
        imgSrc: "/img/apoio/finep.jpg",
        imgAlt: "Finep",
      },
      {
        href: "https://amigosdapoli.com.br/",
        imgSrc: "/img/apoio/amigosdapoli.png",
        imgAlt: "Amigos da Poli",
      },
      {
        href: "https://www.malvernpanalytical.com/br/",
        imgSrc: "/img/apoio/malvern.jpg",
        imgAlt: "Malvern",
      },
    ],
  }),
  methods: {
    verificaCodigoAutenticidade() {
      window.open(
        `https://lct.kemiolab.net/legacy/arquivos/arquivo/${this.codigoAutenticidade}`,
        "__blank"
      );
    },
  },
});
