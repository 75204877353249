













































import Vue from "vue";
import features from "@/tempdata/features";

export default Vue.extend({
  name: "Destaques",
  data: function () {
    return {
      features,
    };
  },
});
