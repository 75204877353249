












import Vue from "vue";
export default Vue.extend({
  name: "CustomImage",
  props: {
    src: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      required: true,
    },
    float: {
      type: String,
      default: "",
    },
    elevation: {
      type: Number,
      default: 2,
    },
  },
  data: () => ({}),
  computed: {
    customClass(): string[] {
      const customClass = [`elevation-${this.elevation}`, "mb-4"];
      if (this.float) {
        if (this.float === "left") customClass.push("mr-md-6");
        if (this.float === "right") customClass.push("ml-md-6");
      }
      return customClass;
    },
    style(): string {
      let style = "";
      if (this.float && ["left", "right"].includes(this.float)) {
        style = `${style}float: ${this.float}; `;
      }
      return style;
    },
    width(): string {
      if (this.$vuetify.breakpoint.smAndDown) return "100%";
      if (this.$vuetify.breakpoint.mdAndDown) return "40%";
      if (this.$vuetify.breakpoint.lgAndDown) return "30%";
      return "20%";
    },
  },
});
