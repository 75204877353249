
















































import Vue from "vue";
export default Vue.extend({
  name: "DialogImage",
  props: {
    imgSrc: {
      type: String,
      required: true,
    },
    thumbnailSrc: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    caption: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "900",
    },
  },
  data: () => ({
    dialog: false,
  }),
});
